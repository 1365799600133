@import '~bootstrap/scss/bootstrap-grid.scss';
@import 'src/scss/mixins.scss';
@import 'src/scss/variables.scss';
.header-user {
	&__right {
		margin-left: auto;
	}

	&__media {
		&--min-75 {
			min-width: 7.5rem;
		}
	}
}

.user-summary {
	border-radius: 3.6rem;
	height: 60px;

	&__name {
		font-family: 'MADE Mellow';
		@include font-size-custom(20px);
	}

	&__points {
		font-family: 'Mont';
		@include font-size-custom(15px);
	}

	&__media {
		overflow: hidden;
		border-radius: 50%;
		width: 6rem;
		height: 6rem;

		img {
			width: 100%;
		}
	}

	svg {
		position: relative;
		fill: $rose;
		display: inline-block;
		margin-left: 0.6rem;
		top: -0.2rem;
	}

	&__right {
		padding: 0.2rem 2.5rem;
	}

	&__hasavatar {
		.user-summary__right {
			padding: 0.2rem 2.5rem 0.2rem 1rem;
		}
	}
}
