body {
	&.content-loading {
		padding-right: 1.7rem;
	}
}

a {
	text-decoration: none;
	transition: all 0.25s linear;
}

ul {
	margin: 0;
	list-style-type: none;
	padding: 0;

	li {
		margin: 0;
		list-style: none;
	}
}

.center-text {
	text-align: center;
}

.color-primary {
	color: $primary;
}

picture {
	display: block;
}

svg {
	display: block;
}

input {
	min-height: 35px;

	.register--check & {
		width: 24px;
		min-height: 24px;
		margin-top: 6px;
		border: 0;
	}
}
