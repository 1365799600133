@import '../../../scss/mixins.scss';
.admin-card-program{
  width: 60%;
  margin-bottom: 3vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 16px;
  background: #FFFFFF;
  border: 2px solid #004F38;
  border-radius: 24px;
  color: #004F38 ;
}

.admin-card-program h1{
  font-size: 3vh;
}

.admin-card-program-img{
  width: 176px;
  height: 177px;
  border-radius: 16px;
}

.admin-card-program p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.admin-card-content-img{
  transition: 500ms;
  width: 30%;
  margin: 5px;
}
.admin-card-content{
  width:70%;
  transition: 500ms;
}

@media (max-width: 1600px) {

  .admin-card-program{
    width: 90%;
  }

  .admin-card-content-img{
    width: 30%;
    margin: 5px;
  }
  .admin-card-content{
    width:70%
  }
  
}

@media (max-width: 1230px) {
  .admin-card-program{
    width: 95%;
  }
}

@media (max-width: 1370px) {
  .admin-card-content-img{
    width: 40%;
  }
  .admin-card-content{
    width:60%;
  }
}

@media (max-width: 1150px) {

  .admin-card-program{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .admin-card-program h1{
  font-size: 2.25vh;
}

  .admin-card-content-img{
    width: 176px;
    height: 177px;
  }
  .admin-card-content{
    width:100%
  }
  
}