@import 'scss/mixins.scss';

.margin-litle {
	margin-top: 0.75vh;
	margin-bottom: 0.75vh;
}

.margin {
	margin-top: 2vh;
	margin-bottom: 2vh;
}

.margin-big {
	margin-top: 3.5vh;
	margin-bottom: 3.5vh;
}

.h1-normal {
	font-family: 'MADE Mellow';
	font-style: normal;
	font-weight: 'bold';
	letter-spacing: 0.03em;
	margin: 0;
	padding: 0;
	margin-top: 1.25vh;
	margin-bottom: 1.25vh;
}

.root-space-between {
	display: flex;
	justify-content: space-between;
}

.p-normal {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 600;
	@include font-size-custom(14px);
	line-height: 22px;
	color: #004f38;
}

.root-space-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
	direction: row;
}

.root-space-between-end {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	direction: row;
}

.root-center-flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.root-end-start {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

.root-center-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.root-center-flex-column {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.position-relative {
	position: relative;
}

.flex-direction-coloum {
	flex-direction: column;
}

.display-block {
	display: block;
}

.display-inline {
	display: inline-block;
}

.display-flex {
	display: flex;
}

.float-left {
	float: left;
}

.loader-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.text-light {
	color: white;
}

.text-gray {
	color: #aaa;
}

.color-fava-admin {
	color: #004f38;
}

.card-border-simple {
	border: 2px solid #004f38;
	border-radius: 1.5vh;
}

.card-border-admin {
	border: 2px solid #004f38;
	padding: 0.75vh 1vh 0;
	border-radius: 2.5vh;
}

.card-border-admin-radius {
	border: 2px solid #004f38;
	padding: 0.75vh 1vh 0;
	border-radius: 4rem;
}

.form-error-input input {
	border: 2px solid red;
}

.form-error-input label {
	color: red;
}

.form-error-input small {
	color: red;
}

.color-red {
	color: red;
}

.form-error-input select {
	border: 2px solid red;
}

.form-error-input .input-wrapper {
	border: 2px solid red;
}

.btn-simple {
	background-color: transparent;
	border: none;
	padding: 0;
	text-align: start;
	width: 100%;
}

.full-width {
	width: 100%;
}

.midle-width {
	width: 70%;
}

.form-select {
	min-height: 38px;
	font-size: 30px;
}

.form-select select {
	font-size: 50px;
}

.form-select option {
	font-size: 15px;
}

@media (max-width: 1000px) {
	.midle-width {
		width: 100%;
	}
}

.font-bold {
	font-weight: bold;
}

.size-big-text {
	font-size: 40px;
}

.full-height {
	width: 100%;
}

.w-90 {
	margin: 0 auto;
	width: 70%;
}

@media (max-width: 1580px) {
	.w-90 {
		width: 100%;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.h-null {
	height: 0;
}

.h-auto {
	height: 500px;
}

.margin-left {
	margin-left: 1vh;
	margin-right: 1vh;
}

.h1-title {
	font-size: 40px;
	line-height: 48px;
	font-weight: 500;
}
select[disabled].form-select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: none;
}

select[disabled].form-select {
	background-color: #e9ecef;
	color: #495057;
	border: 1px solid #ced4da;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
}
