@import 'src/scss/mixins.scss';
.container-group {
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.drawer-group {
	width: 85%;
	margin: 3vh auto;
}

.card-in-drawer {
	width: 48%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px 16px;
	border: 2px solid #004f38;
	border-radius: 16px;
}

.card-in-drawer-description {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 19px;
	line-height: 28px;
	color: #323232;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;
	height: 56px;
}

.input-group-click {
	border: none;
	background-color: white;
}

.title-green-it-info {
	color: #004f38;
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	line-height: 48px;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.auto-complete-classe {
	height: 5vh;
	padding: 0;
}

.comfirm-modal {
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	position: absolute;
}

.comfirm-modal h1 {
	font-size: 3vh;
}

.comfirm-modal p {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	margin: 3vh 0;
	color: #323232;
}

.bottom-btn-teacher{
	position: absolute;
	bottom: 5%;
	margin: 0 auto;
	width: 80%;
}