@import 'variables.scss';

.text {
	&--fava-green {
		color: $fava-green;
	}

	&--white {
		color: $white;
	}

	&--user-rose {
		color: $user-rose;
	}

	&--cornflower-blue {
		color: $cornflower-blue;
	}
}

.bg {
	&--white {
		background: $white;
	}
	&--sea-shell-peach {
		background: $sea-shell-peach;
	}
	&--turbo {
		background: $turbo;
	}
	&--rose {
		background: $rose;
	}
	&--vivid-tangerine {
		background: $vivid-tangerine;
	}

	&--cornflower-blue {
		background: $cornflower-blue;
	}
	
	&--user-rose {
		background: $user-rose;
	}

	&--fava-green {
		background: $fava-green;
	}

	&--grey-light {
		background: $grey-light;
	}
	
}
