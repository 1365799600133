@import '../../../scss/mixins.scss';
@import '../../../scss/variables.scss';
.card-modules{
  position: relative;
  background: #FFFFFF;
  border: 2px solid #004F38;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 40px;
  overflow: hidden;
}

.card-content-modules{
  padding: 2vh;
}

.card-modules-img{
  width: 150px;
  height: 150px;
  border-radius: 25px;
}
 
.card-modules-content{
  margin-left: 30px;
  width: 100%;
}

.card-modules-content h3{
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  color: #004F38;
  font-family: 'Montserrat', sans-serif;
}

.card-modules-content h1{
  font-size: 20px;
  font-family: 'MADE Mellow';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
}

.icon-validation-modules{
  width: 109.83px;
  height: 71px;
}

.card-block-modules{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffc0;
  z-index: 50;
}

.card-current-modules{
  background-color: #004F38;
  color: white;
}


.card-current-modules h3{
  color: white;
}

.card-current-quizz{
  background-image: url("../../../Asset/BackGround/quizzFlach.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 85%;
}



.btn-loading-card{
  width: 350px;
}

.validation-dowload{
  color: #615EFF;
  padding: 1.5vh; 
  cursor: pointer;
}

.validation-dowload img{
  width: 20px;
  height: 20px;
  margin-left: 0.5vh;
  margin-top: -0.25vh;
}

.download-text {
  font-family: 'Mont';
  @include font-size-custom(14px);
  color: $cornflower-blue;
  text-decoration: underline;
}