@import 'mixins.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
.heading {
    &-1 {
        font-family: 'MADE Mellow';
        @include font-size-custom(22px);

        @include media-breakpoint-up(lg) {
            @include font-size-custom(32px);
        }

        @include media-breakpoint-up(xl) {
            @include font-size-custom(40px);
        }
    }
}

.text-center {
    text-align: center;
}