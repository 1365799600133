.uk-timeline-item {
  display: flex;
  position: relative;
}

.uk-line-time-line {
  background: #004F38;
  position: absolute;
  min-height: 105%;
  left: 22px;
  width: 5px;
  z-index: 1;
}

.uk-line-time-line-start{
  background: #FFF6EE; 
  position: absolute;
  left: 22px;
  height: 40%;
  width: 5px;
  z-index: 2;
}

.uk-line-time-line-end{
  background: #FFF6EE; 
  position: absolute;
  height:54%;
  top: 54%;
  left: 22px;
  width: 5px;
  z-index: 2;
}

.uk-badge {
  position: relative;
  top:40%;
  z-index: 2;
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.7rem 0 1.7rem 1rem;
}

.uk-timeline {
  &-item {
    &:last-child {
        .uk-line-time-line {
          min-height: 50%;
        }
    }
  }
}

.uk-badge{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  border: 4px solid #004F38;
  background-color: #fff;

  svg {
    width: 2em;
    height: 2em;
  }
}

.uk-done{
  background-color: #004F38;
  color: white;
}

.uk-repeat{
  border: 4px solid #FF7E7E;
  background-color: #FF7E7E;
  color: white;
}