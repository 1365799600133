@import '../../scss/mixins.scss';
.input-wrapper{
  position: relative;
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  border-radius:0.375rem;
}

.input-wrapper:hover {
  border-color: #40a9ff;
}

.input-wrapper .focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.input-wrapper input {
  font-size: 14px;
  height: 30px;
  box-sizing: border-box;
  padding: 4px 6px;
  width: 0;
  min-width: 30px;
  flex-grow: 1;
  border: 0;
  margin: 0;
  outline: 0;
}

.Label{
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
}

.Tag{
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: rgba(0, 79, 56, 0.2);;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 4px 10px;
  outline: 0;
  overflow: hidden;
}

.Tag:focus {
  border-color: #40a9ff;
  background-color: #e6f7ff;
}

.Tag svg {
  cursor: pointer;
  padding: 4px;
}

.list-box{
  width: 85%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.list-box span {
  flex-grow: 1;
  font-size: 15px;

}

.list-option-autocomplete {
  text-align: start;
  border: none;
  background: none;
  width: 100%;
  padding: 5px 12px;
  display: flex;
  cursor: pointer;
}

.list-option-autocomplete:hover{
  background-color: #d9d9d9;
}

.icon-aucomplete{
  position: absolute;
  z-index: 2;
  right: 4px;
  top: 5px;
}