.program-card-teacher{
  position: relative;
  height: 21.5rem;
  border: 2px solid #004F38;
  border-radius: 2rem;
  width: 100%;
  padding: 1vh 1.5vh;
}

.program-card-teacher img{
  width: 95%;
  height: 19rem;
  border-radius: 1.25rem;
}

.program-teacher-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: space-between;
}