@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

/*Button*/
.button-fava {
	font-family: 'Mont';
	padding: 1rem;
	@include font-size-custom(16px);
	background: none;
	border: 0.2rem solid $cornflower-blue;
	border-radius: 1.4rem;
	@include transition-custom();
	width: 100%;

	@include media-breakpoint-up(lg) {
		padding: 1.28rem 1rem;
		@include font-size-custom(19px);
	}

	&--primary {
		background: $cornflower-blue;
		color: $white;

		&:hover {
			background: $white;
			color: $cornflower-blue;
		}
	}

	&--secondary {
		background: $white;
		color: $cornflower-blue;

		&:hover {
			background: $cornflower-blue;
			color: $white;
		}
	}

	&--vivid-tangerine {
		background-color: $vivid-tangerine;
		color: $white;
		border-color: $vivid-tangerine;

		&:hover {
			background: $white;
			color: $vivid-tangerine;
		}
	}

	&--rose {
    color: $white;
		background-color: $rose;
    border-color: $rose;

    &:hover {
      color: $white;
      background-color: $rose;
		}
	}

  &--fava-green {
    color: $white;
		background-color: $fava-green;
    border-color: $fava-green;

    &:hover {
      color: $white;
      background-color: $fava-green;
		}
	}

  &--small {
    font-size: 11px;
    line-height: 11px;
    padding: 8px 10px 5px;
    text-transform: uppercase;
    border: none;
    border-radius: 3px;
  }
}
