/*Fonts*/
@function rem($px, $base: 10px) {
  @return $px / $base * 1rem;
}

@mixin font-size-custom($size) {
  font-size: $size;
  font-size: rem($size);
}

/*Transitions*/
@mixin transition-custom($property: all, $time: 300ms, $a: 0.215, $b: 0.61, $c: 0.355, $d: 1 , $delay: 0ms ) {
  transition: $property $time cubic-bezier($a, $b, $c, $d) $delay;
}