@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../scss/mixins.scss';
@import '../../../scss/variables.scss';


/*Block*/
.block {
   padding-top: 3.3rem;
   padding-bottom: 3.3rem;

   +.block {
    padding-top: 0;
   }
}

/*heading*/
.heading {
  &-2 {
    font-family: 'MADE Mellow';
    @include font-size-custom(20px);
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      @include font-size-custom(30px);
      margin-bottom: 1.5rem;
    }
  }

  &-3 {
    font-family: 'MADE Mellow';
    @include font-size-custom(18px); 

    @include media-breakpoint-up(lg) {
      @include font-size-custom(26px);       
    }

    &--mb {
      &-medium {
        margin-bottom: 1.4rem;
        @include media-breakpoint-up(lg) {
          margin-bottom: 1.8rem;
        }
      }
    }
  }
}

/*Profile*/
.profile {
  position: relative;
  &__media {
    position: relative;
    width: 8.6rem;
    height: 8.6rem;
    border-radius: 50%;
    cursor: pointer;
    @include transition-custom();
    margin-right: 2.6rem;

    @include media-breakpoint-up(lg) {
        width: 15.6rem;
        height: 15.6rem;
        margin-right: 5.6rem;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }

    &--bordered {
        border: .6rem solid $fava-green;
    }

    &-edit {
      position: absolute;
      width: 2.8rem;
      height: 2.8rem;
      bottom: -.6rem; 
      right: -.6rem;     
      background-color: $fava-green;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;     
      @include transition-custom();

      @include media-breakpoint-up(lg) { 
        width: 4.8rem;
        height: 4.8rem;
      }

      svg {        
        fill: $white;
      }
    } 

    &:hover {
      border-color: $cornflower-blue;

      .profile__media-edit {
        background: $cornflower-blue;
      }
    }
  }

  &__name {
    font-family: 'MADE Mellow';
    @include font-size-custom(20px);

    @include media-breakpoint-up(lg) {
      @include font-size-custom(40px);
    }
  }


  &__category {
    font-family: 'Mont';
    @include font-size-custom(16px);

    @include media-breakpoint-up(lg) {
      @include font-size-custom(20px);
    }

    &-icon {
      max-width: 1.8rem;
      @include media-breakpoint-up(lg) { 
        max-width: 2.4rem;
      }

      svg {
        width: 100%;
      }
    }
  }

}


/*List*/
.list {
  margin-bottom: -1.6rem;
  &__item {
    padding: 1.6rem;
    font-family: 'Mont';
    margin-bottom: 1.6rem;

    &--bordered {
      border: .2rem solid $cornflower-blue;

      &-green {
        border-color: $fava-green;
      }

    }

    &--cornered {
      border-radius: 3rem;
    }

    &-media {

      &--padded {
        padding: .5rem;
      }

      &--size-68 {
        max-width: 3.8rem;
        @include media-breakpoint-up(lg) { 
          max-width: 6.8rem;
        }
      }

      &--mr-a {
        margin-right: 1rem;
      }

      &--brad-img {
        img {
          border-radius: 1.6rem;
        }        
      }

      &--cover{
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &--max-h-167 {

        @include media-breakpoint-up(sm) {
          height: 14.7rem;
          max-height: 14.7rem;
        }

        @include media-breakpoint-up(lg) {
          height: 16.7rem;
          max-height: 16.7rem;
        }
        
      }

      img {
        width: 100%;
      }
    }

    &-text {
      @include font-size-custom(15px);

      @include media-breakpoint-up(lg) { 
        @include font-size-custom(18px);
      }

      &--bold {
        font-family: 'Mont Bold';
      }
      
      &--medium {
        @include font-size-custom(14px);

        @include media-breakpoint-up(lg) { 
          @include font-size-custom(16px);
        }
      }
    }

    &-left {
      width: 100%;
      
      @include media-breakpoint-up(sm) { 
        width: 40%;
      }

      @include media-breakpoint-up(md) {
        width: 46.3%;
      }
    }

    &-right {
      
      width: 100%;

      @include media-breakpoint-up(sm) { 
        width:60%;
        padding: 0 1.2rem;
      }

      @include media-breakpoint-up(md) {
        width: 53.7%;
        padding: 0 1.6rem;
      }
      
    }
  }

  &__line-text {
    margin-bottom: 1.4rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.6rem;
    }
    span {
      font-family: 'MADE Mellow';
    }
  }

  &__btn {
    margin-top: 1.6rem;
  }

}