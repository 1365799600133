@import '../../../scss/mixins.scss';
.password-input {
	position: relative;
}

.text-white-connection {
	font-family: 'MADE Mellow';
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	text-align: center;
	color: #ffffff;
}

.text-white {
	font-family: 'Mont';
	color: #ffffff;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 26px;
}

.text-danger {
	color: '#FF7E7E';
}

.password-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
}

.form-group-mg-top {
	margin-top: 10px;
}

.rounded-input {
	border-radius: 40px;
	height: 50px;
}

.submit-btn {
	border-radius: 14px;
	height: 58px;
	margin-top: 25px;
	font-family: 'Mont';
	font-style: normal;
	font-weight: 800;
	font-size: 19px;
	line-height: 28px;
}

.connect-btn {
	border-radius: 34px;
	height: 58px;
	font-family: 'Mont';
	font-style: normal;
	font-weight: 800;
	font-size: 19px;
}

.label-container {
	display: flex;
	align-items: center;
}

.password-input {
	display: flex;
	align-items: center;
}

.alert-icon {
	position: absolute;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
}

.password-input .password-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
}

.label-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
