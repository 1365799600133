@import 'src/scss/mixins.scss';
@import 'src/scss/variables.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

.p-rel {
	position: relative;
}

.p-static {
	position: static;
}

.p-abs {
	position: absolute;
}

/*** Bar chart ***/
.bar-chart {
	&-outer-wrap {
		margin-top: 40px;
		margin-bottom: 20px;
		font-family: 'Mont';
		@include media-breakpoint-up(lg) {
			margin-top: 20px;
			padding-left: 13.5rem;
			margin-bottom: 0;
			padding-right: 12.5rem;
		}
	}

	&__horizontal {
		&--horizontal {
			padding-top: 14px;
		}

		&-bar {
			height: 2rem;

			> div {
				height: inherit;
			}

			&-title {
				@include font-size-custom(13px);
				text-align: left;

				padding-left: 0.8rem;

				@include media-breakpoint-up(lg) {
					position: absolute;
					right: 100%;
					width: 20rem;
					text-align: right;
					@include font-size-custom(13px);
					top: 50%;
					transform: translateY(-50%);
					padding-right: 2rem;
					padding-left: 0;
				}
			}

			@include media-breakpoint-up(lg) {
				height: 2.7rem;
			}

			&--first {
				.bar-chart__digit--out {
					color: $rose;
				}
			}

			&--second {
				.bar-chart__digit--out {
					color: $fava-green;
				}
			}
		}

		&-groups {
			border-left: 0.3rem solid $fava-green;
			border-bottom: 0.3rem solid $fava-green;
			padding: 1.6rem 0 2.4rem;

			&:after {
				position: absolute;
				width: 2rem;
				height: 3rem;
				content: '';
				bottom: -0.1rem;
				right: -0.4rem;
				background: url('../../Asset/Icon/arrow-point-right.svg') no-repeat 0 0;
				transform: translateY(50%);
			}
		}

		&-group {
			margin-bottom: 1.6rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__fill {
		&-background {
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0;
			background: $rose;

			&--bottom {
				border-bottom-right-radius: 0.5rem;
			}

			&--compare {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0.5rem;
				background: $fava-green;

				&-top {
					border-top-right-radius: 0.5rem;
				}
			}
		}
	}

	&__digit {
		color: $white;
		top: 0;
		right: 0.7rem;
		@include font-size-custom(14px);
		line-height: 4;
		@include media-breakpoint-up(lg) {
			@include font-size-custom(15px);
		}
		&--out {
			left: 108%;
		}
	}

	&__duration {
		line-height: 1;
		margin-top: 0.5rem;
		margin-left: 0.8rem;
		position: relative;
		padding: 0.6rem 1rem 0.6rem 2rem;
		@include font-size-custom(9px);
		border: 0.2rem solid $cornflower-blue;
		display: inline-flex;
		border-radius: 1rem;
		background: url('../../Asset/Icon/clock.svg') no-repeat 0 0;
		background-size: 1rem;
		background-position: 0.5rem 46%;

		@include media-breakpoint-up(lg) {
			margin: 0;
			position: absolute;
			left: 104%;
			top: 50%;
			transform: translateY(-50%);
			width: 11.5rem;
		}
	}
}
