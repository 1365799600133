@import '../../../scss/variables.scss';

.root-btn-filter {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9px 20px 9px;
	gap: 8px;
	height: 58px;
	background: #ffffff;
	border: 2px solid #615eff;
	color: #615eff;
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	border-radius: 15px;
	flex: none;
	order: 0;
	flex-grow: 0;
	cursor: pointer;
	margin-left: 0.5vh;
	margin-right: 0.5vh;
	overflow: hidden;
}

.menu-filter-list {
	width: 200px;
	padding: 0;
}

.menu-filter-list li {
	width: 200px;
	font-size: 14px;
}

.filter-class {
	max-width: 224px;
	width: 100%;
}

.MuiMenu-paper.MuiPopover-paper {
	box-shadow: 0 0px 1px #00000088;
	border-radius: 8px;
	margin-top: 5px;
	max-width: 100%;
	width: 224px;

  .MuiMenu-list {
    max-height: 200px;
    overflow-y: auto;
  }

  .MuiMenuItem-root {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $grey-text;
    width: 100%;

    &:hover {
      color: $white;
      background: $cornflower-blue;
    }
  }
}
