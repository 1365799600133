@import '../../../scss/mixins.scss';
.content-title-complicated{
  position: relative;
  width: 400px;
  height: 49px;
  background: #FFFFFF;
  border-radius: 67px;
  overflow: hidden;
}

.content-title-percentage{
  position: absolute;
  height: 100%;
  display: flex;
  padding-left: 2vh;
  background-color: #FFEA00;
  border-radius: 67px;
  z-index: 10;
}

.content-complicated{
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  direction: row;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  z-index: 15;
  margin-left: 5%;
}

.content-complicated-text{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content-flex-end-complicated-h1{ 
  font-weight: bold;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  color: #004F38;
  letter-spacing: 3px;
}

.content-flex-end-complicated-p{
  font-size: 17.5px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #004F38;
  margin-left: 5px;
  margin-top: 10px;
}
