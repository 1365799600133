@import 'src/scss/mixins.scss';
.container-student {
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.width-btn-edit {
	width: 80%;
	border: 1px solid;
}

.card-simple-admin-program-stat {
	border: 2px solid rgba(0, 79, 56, 1);
	border-radius: 14px;
	padding: 1vh;
}

.card-simple-admin-program-stat img {
	width: 100%;
	margin-bottom: 1vh;
}

.card-simple-admin-program-stat h1 {
	font-size: 2.5rem;
	margin-top: 0;
}

.card-simple-admin-program-stat p {
	font-size: 1.25rem;
	margin: 0;
	padding: 0;
}

.card-simple-admin-program-stat span {
	font-weight: bold;
	color: rgba(97, 94, 255, 1);
}

.chip-teacher-admin-class {
	border: 2px solid rgba(97, 94, 255, 1);
	border-radius: 10px;
	font-size: 10px;
	overflow: hidden;
}

.chip-teacher-admin-class h2 {
	font-size: 15px;
}

.chip-teacher-admin-class .img-content-chip {
	background-color: rgba(97, 94, 255, 1);
	margin: 0;
	padding: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.no-padding {
	padding: 0;
}

.bottom-btn-classe {
	position: absolute;
	bottom: 5%;
	margin: 0 auto;
	width: 80%;
}

.pops {
	&--title {
		font-family: 'MADE Mellow';
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 16px;
	}

	&--btn {
		margin: 30px 0;
	}
}
