@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $white;
	width: 100%;
	height: auto;
	padding: 15px 0;
	z-index: 2;
	position: fixed;
	left: 0;
	bottom: 0;
	-webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);

	&_list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}

		li {
			font-family: 'Montserrat', sans-serif;
			font-size: 14px;
			line-height: 14px;
			font-style: normal;
			color: $fava-green;
			position: relative;
			display: flex;
			align-items: center;
			padding: 6px;

			@include media-breakpoint-up(sm) {
				padding: 0;
			}

			&:after {
				content: '';
				width: 4px;
				height: 4px;
				background: $fava-green;
				border-radius: 50%;
				margin: 0 12px;
				display: none;

				@include media-breakpoint-up(sm) {
					display: inline-block;
				}
			}

			&:last-child {
				margin-right: 0;
				display: flex;
				flex-direction: column;

				@include media-breakpoint-up(sm) {
					flex-direction: row;
				}

				&:after {
					display: none;
				}
			}
		}

		a {
			font-family: 'Mont Bold';
			font-size: 14px;
			line-height: 14px;
			font-style: normal;
			text-decoration: none;
			color: $fava-green;
			transition: all 0.25s linear;
			position: relative;
			display: flex;
			align-items: center;
			margin: 5px 0;

			@include media-breakpoint-up(sm) {
				margin: 0;
			}

			&:after {
				content: '';
				width: 4px;
				height: 4px;
				background: $fava-green;
				border-radius: 50%;
				margin: 0 12px;
				display: none;

				@include media-breakpoint-up(sm) {
					display: inline-block;
				}
			}

			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}

	.loading & {
		position: fixed;
		left: 0;
		bottom: 0;
	}
}
