@import '../../../scss/mixins.scss';
.card-help{
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 24px;
  gap: 24px;
  width: 38rem;
  min-height: 290px;
  background: #004F38;
  border-radius: 16px;
  color: white;
}

.card-help h1{
  font-size: 3vh;
  font-family: 'MADE Mellow';
}