@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';

.progress {
	&-collection {
		.progress-simple {
			margin-bottom: 1.1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&-simple {
		border-radius: 2rem;
		border: 0.2rem solid transparent;
		@include transition-custom();
		padding: 0.2rem 1.3rem;
		z-index: 1;
		min-height: 3rem;

		&--big {
			border-radius: 6.7rem;

			.progress-simple__bg {
				border-radius: 6.7rem;
			}

			.progress-simple__media {
				font-size: 2rem;
			}


			.progress-simple__text {
				>div {
					display: flex;
					align-items: center;
				}
			}

			
		}

		&--border {
			&-turbo {
				border-color: $turbo;
			}

			&-rose {
				border-color: $rose;
			}

			&-vivid-tangerine {
				border-color: $vivid-tangerine;
			}
		}

		&__bg {
			@include transition-custom();
			top: 0;
			left: 0;
			height: 100%;
			border-radius: 2rem;
			z-index: 1;
		}

		&__content {
			font-family: 'Mont Light';
			@include font-size-custom(12px);
			z-index: 2;
			font-weight: 600;
		}

		&__text {
			padding-right: 1rem;

			&--big {
				@include font-size-custom(20px);
				.progress__unit {
					@include font-size-custom(35px);					 
					font-family: 'Mont Bold';
					margin-right: 1rem;
				}
			}
		}

 

		&__media {
			color: $fava-green;
		}
	}
}
