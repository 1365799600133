@import '../../../../../scss/mixins.scss';
@import '../../../../../scss/variables.scss';

.root-btn-question {
	padding: 12px 17px;
	//min-width: 404px;
	width: 100%;
	/*   height: 58px; */
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 19px;
	margin: 1.5vh;
	border-radius: 34px;
	border: 2px solid #615eff;
	display: flex;
	align-items: center;
	color: #615eff;
	cursor: pointer;
	background-color: white;
	z-index: 2;
	text-align: left;

	&.faild-btn {
		background: $vivid-tangerine;
	}

	.root-content-question-response--with-image & {
		line-height: 22px;
		text-align: left;
		min-width: 100%;
		width: 100%;
		margin: 1.5vh 0;
	}
}

.root-btn-question:hover {
	transform: scale(0.99);
}

.root-btn-question:active {
	transform: scale(0.98);
}

.root-btn-question span {
	border: 2px solid #615eff;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1.25vh;
}

.response-image {

	&--no-height {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 2rem;
	}

	span.response-image-span-relative {
		position: relative;
		top: 0;
		left: 0;
	}
}

.answer-text {
	color: $cornflower-blue;
	@include font-size-custom(18px);
	font-family: 'Mont';
	margin-left: 1.5rem;

	.btn-clicked-question & {
		color: $white;
	}
}

.btn-clicked-question {
	background-color: #615eff;
	color: white;
}

.btn-clicked-question span {
	border: 2px solid white;
}

.content-btn-question {
	position: relative;
}

.background-success-question {
	background-color: #fd99ff;
	border-radius: 34px;
	position: absolute;
	min-width: 404px;
	height: 58px;
	top: 8px;
	left: 13px;
	z-index: -1;
}

.faild-btn {
	background: #ff7e7e;
	color: white;
	border: none;
}

.faild-btn span {
	border: 2px solid white;
}
