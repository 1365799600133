@import '../../../scss/mixins.scss';
.success-question {
	text-align: center;
	color: #004f38;
}

.success-question p {
	width: 60%;
	margin: 0 auto 20px;
	font-family: 'MADE Mellow';
	font-size: 18px;
	line-height: 20px;
	font-weight: 800;
	text-align: center;
}
