@import '../../../scss/mixins.scss';
.img-title-ChipUser {
	width: 55px;
	height: 55px;
	border-radius: 55px;
}

.content-progress-chapitre {
	margin-left: 2vh;
}

.img-span-ChipUser {
	width: 20px;
	height: 20px;
	position: relative;
	top: -3px;
	left: 5px;
}

.chip-user-contains {
	background: #615eff;
	padding: 0 40px;
	border-radius: 55px;
	overflow: hidden;
	height: 55px;

	&__hasavatar {
		padding: 0 40px 0 0;
	}

	> div {
		height: 100%;
	}

	&__avatar {
		margin-right: 15px;
	}
}

.chip-user-contains h3 {
	font-family: 'MADE Mellow';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	letter-spacing: 0.01em;
	color: #ffffff;
}

.content-score-ships {
	display: flex;
	color: white;
	font-family: 'Montserrat', sans-serif;
}

.content-element-ChipUser {
	//margin-left: 15px;
}
