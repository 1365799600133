@import '../../../scss/mixins.scss';
.form-header-text-white {
	color: #ffffff !important;
	font-family: 'Mont';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 16px;
}

.form-group-mg-top {
	margin-top: 10px;
}

.text-white {
	color: '#FFFFFF' !important;
	font-family: 'Mont';
	font-style: normal;
}

.text-danger {
	color: #ff7e7e !important;
	font-family: 'Mont';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
}

.password-input {
	position: relative;
}

.password-input .password-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
}

.rounded-input {
	border-radius: 40px;
	height: 50px;
}

.submit-btn {
	border-radius: 14px;
	height: 58px;
	margin-top: 25px;
	margin-bottom: 25px;
}

.input-text {
	font-family: 'Mont';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #004f38;
}

.cgu-label {
	//margin-left: 10px;
	font-family: 'Mont';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 26px;
	color: #ffffff;

	&__link {
		text-decoration: underline;
		padding-left: 4px;
	}
}

.form-check-input:checked[type='checkbox'] {
	--bs-form-check-bg-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27green%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
}

.form-check-input:checked[type='checkbox'] {
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27green%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
}

.form-check-input {
	&:checked {
		background-color: #fff;
		border-color: #004f38;
	}

	&:focus {
		box-shadow: none;
	}
}

.register {
	&--logo {
		svg {
			margin: 0 auto;
		}
	}

	&--check {
		margin-top: 3.5rem;
		margin-bottom: 3.5rem;

		> div {
			margin-right: 10px;
			flex-shrink: 0;
		}
	}
}

.form-register {
	max-width: 370px;
	width: 100%;

	.submit-btn {
		margin-top: 0;
	}
}

.form-label {
	margin-bottom: 0;
}

.form-group-mg-bottom {
	margin-bottom: 16px;
}
