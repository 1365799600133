@import '../../scss/mixins.scss';

.root-TitlePage{
  display: flex;
  font-family: 'MADE Mellow';
  font-style: normal;
  font-weight: 'bold';
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.03em;
  color: #004F38;
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media (max-width: 1500px) {
  .root-TitlePage{
    font-size: 35px;
    line-height: 40px;
  }
}

@media (max-width: 1300px) {
  .root-TitlePage{
    line-height: 35px;
    font-size: 30px;
  }
}

@media (max-width: 1150px) {
  .root-TitlePage{
    font-size: 30px;
  }
}


@media (max-width: 900px) {
  .root-TitlePage{
    font-size: 20px;
  }
}

@media (max-width: 800px) {
  .root-TitlePage{
  font-size: 20px;
  }
}

.span-TitlePage{
  position: relative;
  top: 10px;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 42px;
  height: 42px;
  background: #FFEA00;
  font-size: 15px;
  border-radius: 200px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 2vh;
}