.container-details-info{
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.container-details-student-admin-tabs{
  display: flex;
  width: 100%;
  position: relative;
  background: rgba(0, 79, 56, 0.15);
  border-radius: 12px;
  overflow: hidden;
}

.container-details-tab{
  width: 33.35%;
  text-align: center;
  padding: 1vh;
  cursor: pointer;
}

.active{
  background: #004F38;
  font-family: 'Mont';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.width-form-details-student-admin{
  width: 25%;
}

@media (max-width: 1440px) {
  .width-form-details-student-admin{
    width: 35%;
  }
}