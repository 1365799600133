.chip-time-contains{
  background: #FFEA00;
  border-radius: 55px;
  padding: 5px 10px;
  overflow: hidden;
  width: 100px;
  color: black;
}

 .chip-icon-contains{
  margin-top: -6px;
  margin-right: 3px;
 }