@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.img-logo-quizz {
	position: absolute;
	left: 0;

	@media (max-width: 767.99px) {
		position: static;
		margin-bottom: 20px;
	}
}

.quiz {
	&__height {
		height: 91px;
	}

	&__final {
		height: 127px;

		@media (max-width: 767.99px) {
			flex-direction: column;
			height: auto;
		}
	}

	&__image {
		max-width: 300px;
		width: 100%;
		margin: 0 auto;

		img {
			width: 100%;
			height: auto;
			border-radius: 40px;
			border: 2px solid #004f38;
		}
	}

	&__video {
		> div {
			@media (max-width: 767.99px) {
				width: 100% !important;
			}

			> div {
				padding: 0 !important;
				border: 2px solid #004f38;
				border-radius: 40px;
				height: 184px;
				overflow: hidden;

				@include media-breakpoint-up(lg) {
					height: 394px;
				}

				iframe {
					position: static !important;
					width: 320px !important;
					height: 180px !important;

					@include media-breakpoint-up(lg) {
						width: 700px !important;
						height: 394px !important;
					}
				}
			}
		}
	}
}
