@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';

.loader-custom {
	background: rgba($sea-shell-peach, 0.5);

	&--transparent {
		background: transparent;
	}

	&--absolute {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&--relative {
		position: relative;
		padding: 2rem;
	}
}

@keyframes fade {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.blink {
	animation: fade 1000ms infinite;
}

.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	border-top: 4px solid $fava-green;
	border-right: 4px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	top: 0;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border-left: 4px solid $cornflower-blue;
	border-bottom: 4px solid transparent;
	animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
