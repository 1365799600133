.info-details-tabs{
  margin: 2vh 0;
}

.text-btn{
  font-family: 'Mont';
  color: #615EFF;
}

.content-form-info{
  width:30%;
}