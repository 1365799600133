@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../../../scss/mixins.scss';
@import '../../../../../scss/variables.scss';

.img-logo-quizz {
	position: absolute;
	left: 0;
}

.img-apercu-content-question {
	// width: 330px;
	// height: 332px;
	width: 100%;
	height: auto;
}

.root-content-question-response {
	margin: 5vh 0;

	&--with-image {
		width: 100%;
		margin: 0 auto;
		flex-direction: column;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}

		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		.content-ques__image {
			width: 100%;

			@include media-breakpoint-up(sm) {
				width: 330px;
			}
		}

		.content-btn__wrap {
			width: 100%;

			@include media-breakpoint-up(sm) {
				width: calc(100% - 330px);
				max-width: 100%;
				padding-left: 20px;
			}
		}
	}
}

.root-content-question-response h2 {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 39px;
	text-align: center;
	color: #004f38;
	margin: 15px auto 25px;

	@include media-breakpoint-up(lg) {
		width: 60%;
	}

	.content__final & {
		color: $white;
	}
}

.root-container-question {
	display: flex;
	justify-content: start;
	flex-direction: row;
}

.grid-container {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 50px;
	padding: 10px;
}

.content-img-question {
	backdrop-filter: blur(3.5px);
	width: 220px;
	height: 220px;
	border-radius: 40px;
	border: 1px solid;
	background-size: cover;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.content-img-question:hover {
	transform: scale(0.99);
}

.content-img-question:active {
	transform: scale(0.98);
}

.content-img-question span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px;
	gap: 8px;
	background: #ffffff;
	border: 2px solid #615eff;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	margin: 2vh;
}

.content-not-click-img-question {
	position: absolute;
	z-index: 2;
	background: rgba(227, 238, 235, 0.3);
	width: 100%;
	height: 100%;
	backdrop-filter: blur(3.5px);
}
