.container-student {
	margin-top: 5vh;
	margin-bottom: 5vh;
	border-radius: 7px;
	background: #e5e7eb;
}

.chip-filter-student .MuiChip-root {
	border: 1px solid;
	background-color: #004f38;
	font-size: 10px;
}

.chip-filter-student .MuiChip-label {
	font-size: 15px;
}
