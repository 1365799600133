// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/reboot.scss'; 
@import 'scss/mixins.scss';
@import 'scss/heading.scss';
@import 'scss/colors.scss';
@import 'scss/bootstrap-override';
@import 'scss/misc-elements';
@import 'scss/base.scss';

@font-face {
	font-family: 'MADE Mellow Light';
	src: url('./Fonts/madeMellow/MADEMellowPERSONALUSE-Light.otf')
		format('opentype');
}

@font-face {
	font-family: 'MADE Mellow Medium';
	src: url('./Fonts/madeMellow/MADEMellowPERSONALUSE-Medium.otf')
		format('opentype');
}

@font-face {
	font-family: 'MADE Mellow';
	src: url('./Fonts/madeMellow/MADEMellowPERSONALUSE-Regular.otf')
		format('opentype');
}

@font-face {
	font-family: 'MADE Mellow Bold';
	src: url('./Fonts/madeMellow/MADEMellowPERSONALUSE-Bold.otf')
		format('opentype');
}

@font-face {
	font-family: 'Mont Light';
	src: url('./Fonts/mont/Mont-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Mont';
	src: url('./Fonts/mont/Mont-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Mont Bold';
	src: url('./Fonts/mont/Mont-Bold.otf') format('opentype');
}

// Override default variables before the import
$theme-colors: (
	'primary': #615eff,
	'danger': #ff7e7e,
	'info': #ffea00,
	'success': #fd99ff,
	'warning': #ffea00,
);

html {
	// This defines what 1rem is
	font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
}

body {
	@include font-size-custom(16px);
}

.min-h-100vh-minus-header {
	//min-height: calc(100vh - 7.5rem);
}
