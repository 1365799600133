.content-link-copy{
  height: 42px;
  padding: 1px 10px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.border-link-copy{
  border: 1px solid rgba(209, 213, 219, 1);
}

.input-group-click-link-copy{
  border: none;
  background-color: white;
  position:absolute;
  width: 100%;
}


.btn-icon-copy-link{
  position:absolute;
  top: 5px;
  right: 15px;
  background-color: white;
}