@import '../../../scss/mixins.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
.card-group {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.page-title {
	font-family: 'Mont';
	font-style: normal;
	font-weight: 500;
	@include font-size-custom(30px);
	letter-spacing: 0.03em;
	color: #004f38;
	margin-left: 0;
	padding-top: 2rem;
	margin-bottom: 2rem;

	@include media-breakpoint-up(lg) {
		@include font-size-custom(40px);
	}
}


.equal-height-cards {
	display: flex;
	flex-wrap: wrap; 
}
