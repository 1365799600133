@import '../../../scss/mixins.scss';
.card-program-admin{
  position: relative;
  background: #FFFFFF;
  border: 2px solid #004F38;
  margin-top: 1vh;
  margin-bottom: 1vh;
  overflow: hidden;
  border-radius: 2rem;
  padding: 1vh;
}

.card-content-program-admin{
  padding: 2vh;
}

.card-program-img{
  width: 17rem;
  height: 15rem;
  border-radius: 1.5rem;
}
 
.card-program-content-admin{
  margin-left: 30px;
  width: 100%;
}

.card-program-content-admin h3{
  font-size: 20px;
  font-family: 'MADE Mellow';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #004F38;
}

.card-program-content-admin h1{
  font-family: 'MADE Mellow';
  font-style: normal;
  font-weight: 'bold';
  font-size: 20px;
  color: #004F38;
}
 
.card-program-content-admin p{
  font-size: 15px;
  font-style: normal;
  text-overflow: ellipsis;
  color: #004F38;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

 