@import '../../scss/variables.scss';

.thead {
	border-radius: 20px;
	overflow: hidden;
}

.thead tr {
	overflow: hidden;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	border: none;
	background-color: #004f38;
}

.thead tr th {
	background-color: #004f38;
	text-transform: uppercase;
	color: white;
	border: none;
}

.th-body {
	border: 1px solid #e5e7eb;
	//min-height: 52px;
	margin: 0;
	padding: 0;
	border-bottom: none;
}

.tr-body {
	th {
		border: none;
		margin: 0;
		padding: 0;
		//max-width: 300px;

		&:last-child {
			.th-body {
				//border-right: 1px solid #e5e7eb;
			}
		}
	}

	.th-body {
		border-right: none;

		> div {
			//min-height: 40px;
			align-items: center;
		}

		svg {
			font-size: 1.85rem;
			fill: $fava-green;
		}
	}

	&:last-child {
		.th-body {
			//border-bottom: 1px solid #e5e7eb;
		}
	}
}

.head-top-left {
	border-top-left-radius: 8px;
}

.head-top-right {
	border-top-right-radius: 8px;
}

.body-bottom-left {
	border-bottom-left-radius: 8px;
}

.body-bottom-right {
	border-bottom-right-radius: 8px;
}

.ArrowForwardIosIcon-on-select-table {
	cursor: pointer;
}

.chip-array {
	display: inline-block;
	margin: 2px;
	background-color: rgba(0, 79, 56, 0.2);
	border-radius: 2px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.limit-array-chip {
	float: right;
	width: 33px;
	height: 33px;
	border-radius: 33px;
	display: flex;
	background-color: #aaaaaaad;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.container-student {
	.table > :not(caption) > * > * {
		// padding: 16px 24px 12px;
		border: none;
		box-shadow: none;
		padding: 1.6rem 2.4rem;
	}
}
