@import '../../../scss/mixins.scss';
@import '../../../scss/variables.scss';
@import '~bootstrap/scss/bootstrap-grid';

/* .fv-module-card {
	padding: 25px;
}

@media (max-width: 576px) {
	.fv-module-card {
		align-items: center;
		padding: 25px;
	}
} */

.card-horizontal {
	padding: 1.8rem;
	border-radius: 4rem;
	border: .2rem solid $fava-green;
	position: relative;

	&--min-168 {
		min-height: 16.8rem;
	}

	@include media-breakpoint-up(lg) {
		padding: 2.2rem;
	}

	&--pt {
		padding-top: 3.4rem;
	}

	&--ml {
		margin-left: 2.7rem;
	}

	>div {
		position: relative;
		z-index: 2;
	}

	&--with-artwork {
		z-index: 1;
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			background-image: url("../../../Asset/BackGround/quizzFlach.svg");
			background-size: auto 100%;
			background-repeat: no-repeat;
			background-position-x: 80%;
			
		}		 
	}

	&.bg--fava-green {
		&.card-horizontal--with-artwork {
				&:after {			 
					opacity: .3;
				}
		}
	}

	&.bg--disabled {
		background: $white;

		.card-horizontal__media {
			filter: blur(3px);
		}

		.card-horizontal__title, .card-horizontal__subtitle, .progress-simple__text {
			color: $grey-disabled;
		}

		.card-horizontal__progress {
				filter: grayscale(100%);
		}

		.button-fava {
			background: $grey-disabled;
			border-color: $grey-disabled;
			color: $white;
		}
		
	}

	&__subtitle {
		font-family: 'Mont Bold';
		@include font-size-custom(12px);		
		letter-spacing: .4rem;

		.bg--fava-green & {
			color: $white;
		}
	}

	&__title {
		font-family: 'Mont';
		@include font-size-custom(20px);		

		@include media-breakpoint-up(lg) { 
			@include font-size-custom(30px);		
		}

		&--mb {
			margin-bottom: 1rem;
		}

		.bg--fava-green & {
			color: $white;
		}
	}

	&__media {
		@include media-breakpoint-up(lg) { 
			width: 25%;
			max-width: 12.4rem;
		}

		img {
			width: 100%;
			border-radius: 2.5rem;
			overflow: hidden;
		}
	}


	&__details {
		@include media-breakpoint-up(lg) { 
			padding-left: 2.4rem;
			width: 50%;
		}
	}

	&__link {
		@include media-breakpoint-up(lg) { 
			width: 25%;
			margin-left: auto;
		}
	}


	&__progress-special-media-wrapper {
		
		.card__progress {
			width: calc(100% - 11rem);
			max-width: 26.9rem;
		}
		 
	}

	

	
}

/* .fv-card-title {
	font-size: 14px;
	@include font-size-custom(12px);
	font-family: Mont;
	color: #004f38;
}

.fv-card-subtitle {
	color: #004f38;
	@include font-size-custom(30px);
	font-family: 'Mont';
	line-height: 30px;
	margin-top: 1rem;

	&--mb {
		margin-bottom: 1rem;
	}
}

.fv-progress-bar {
	margin-top: 10px;
	width: 50%;
}

.fv-module-card-btn-container {
	align-self: center;
	align-items: center;
}

.fv-module-card-btn {
	width: 100%;
	@include font-size-custom(19px);
}

.fv-module-card-details-container {
	padding-bottom: 10px;
}

.fv-module-progress-bar-container {
	background-color: #ffffff;
	border: 2px solid #ffea00;
	border-radius: 24px;
	height: 24px;
	margin-top: 3px;
}

.fv-module-success-progress-bar-container {
	background-color: #ffffff;
	border: 2px solid #fd99ff;
	border-radius: 24px;
	height: 24px;
	margin-top: 9px;
}

.fv-bar-content {
	color: #004f38;
	text-align: left;
	white-space: pre-wrap;
}

.fv-check-icon {
	color: #004f38;
	margin-right: 12px;
}

.fv-trophy-icon {
	position: absolute;
	right: 30px;
	margin-top: 7px;
	color: #004f38;
}

.fv-progress-bar-custom-label {
	color: #004f38;
	margin-left: 12px;
	@include font-size-custom(12px);
	line-height: 20px;
}

.fv-module-image-container {
	height: 20vh;
	overflow: hidden;
	display: flex;
} */

/* @media (max-width: 576px) {
	.fv-module-image-container {
		height: 20vh;
		overflow: hidden;
		display: flex;
	}
}
 */
/* .fv-module-image { */
	/* width: 100%;  *//* or any custom size */
/* 	height: 100%;
	object-fit: contain;
}
 */
/* .fv-bar-content-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	position: absolute;
}
 */