@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'src/scss/mixins.scss';
@import 'src/scss/variables.scss';

.root {
	margin: 0;
	padding: 0;
}

.sidebar {
	position: relative;
	width: 200px;
	padding: 2.4rem 1.5rem 4rem;
	background: $white;
	border-right: 2px solid $fava-green;
	min-height: 100vh;

	&__bottom {
		margin-top: auto;
		padding-bottom: 5rem;
	}

	&-content {
		&-wrapper {
			padding-bottom: 5vh;
		}
	}
}

.section-content {
	position: relative;
	padding: 5vh 5vh;
	// height: 100vh;
	// overflow-y: scroll;
}

.section-student {
	padding-bottom: 15vh;
	background: $sea-shell-peach;
}

.site-logo {
	width: 9.5rem;
}

.menu-fava {
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;

	&--mt {
		margin-top: 7rem;
	}

	&--mb {
		margin-bottom: 5rem;
	}

	a {
		color: $mine-shaft;
		text-decoration: none;
		padding: 0.8rem 1.2rem;
		border-radius: 1rem;
		display: block;
		@include font-size-custom(14px);
		font-family: 'Mont';

		@include transition-custom();

		&.active,
		&:hover {
			color: $white;
			background: $cornflower-blue;
			font-weight: normal;
			@include font-size-custom(14px);

			.menu {
				&-stroke {
					stroke: $white;
				}

				&-fill {
					fill: $white;
				}
			}
		}

		svg {
			margin-right: 1rem;
		}
	}

	li {
		margin-bottom: 1.6rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.menu {
	&-stroke {
		stroke: $mine-shaft;
		@include transition-custom();
	}

	&-fill {
		fill: $mine-shaft;
		@include transition-custom();
	}
}

.full-logo {
	width: 11.7rem;
}

.cursor-pointer {
	cursor: pointer;
}
