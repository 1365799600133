table {
	&.table {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		border-radius: 1rem;
		overflow: hidden;
		font-family: 'Mont';

		thead {
			vertical-align: top;

			&.thead {
				tr {
					th {
						background-color: $fava-green;
						padding: 1.6rem 2.4rem;
					}
				}
			}
		}

		td,
		th {
			font-weight: normal;
		}

		.tr-body {
			background: #e5e7eb;
			border: 1px solid #e5e7eb;
			border-top: none;
			vertical-align: top;

			&:last-child {
				//border: none;
			}

			th {
				background: $white;
				border-right: 1px solid #e5e7eb;

				&:last-child {
					border: none;
				}
			}
		}
	}
}

.line-ellipsis {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: normal; /* let the text wrap preserving spaces */
	&--3 {
		-webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
	}
}
