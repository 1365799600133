@import '../../scss/mixins.scss';
.navbar-header {
	// height: '8rem';
	background-color: #ffffff;
}

.header-text {
	color: #004f38;
	font-family: 'MADE Mellow Light';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.01em;
	/* margin-left: 3.5rem; */
	white-space: normal;	
}

.flex-container {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 100px);
	background-color: #fff6ee;
}
