.content-line-Simple{
  position: relative;
  width: 60%;
  height: 30px;
  background: #FFFFFF;
  border-radius: 67px;
  overflow: hidden;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border: 2px solid #004F38;
  transition: 500ms;

  @media (max-width: 767.99px) {
    width: 100%;
	}
}

.content-line-percentage{
  position: absolute;
  height: 100%;
  display: flex;
  padding-left: 2vh;
  z-index: 10;
  border-top-right-radius: 64px;
  border-bottom-right-radius: 64px;
  transition: 500ms;
}

.content-Simple{
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  direction: row;
  justify-content: flex-end;
  position: absolute;
  height: 100%;
  z-index: 15;
  margin-left: 5%;
}

 
 