.root-contenu-program-teacher-quiz-h1{
  color: #004F38;
  font-weight: bold;
  font-size: 2.25rem;
}

.content-list-teacher-admin-width{
  width: 65%;
  transition: 500ms;
}

@media (max-width: 1440px) {
  .content-list-teacher-admin-width{
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .content-list-teacher-admin-width{
    width: 100%;
  }
}