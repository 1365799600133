@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';

.content-title {
	font-family: 'MADE Mellow';
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 48px;
	text-align: center;
	letter-spacing: 0.03em;
	color: #004f38 !important;
	margin-top: 2%;
}

.progress-bar-content-card {
	width: 50vw;
	background-color: #ffffff;
	border: 2px solid #004f38;
	border-radius: 24px;
	height: 20px;
}

.text-green {
	font-family: 'Mont';
	font-size: 16px;
	color: #004f38;
	padding: 5% 15% 2% 15%;
	color: #004f38;
}

.start-button {
	height: 58px;
	padding: 12px 17px 9px 17px;
	font-family: 'Mont';
	@include font-size-custom(19px);
	line-height: 19px;
	color: $cornflower-blue;
	font-weight: 700;
	width: 268px;
	border-radius: 14px;
	background-color: $white;
	border: 2px solid $cornflower-blue;

	&:active, &:hover {
		color: $white;
		background: $cornflower-blue;
		border: 2px solid $cornflower-blue;
	}
}

.next-button {
	height: 58px;
	padding: 12px 17px 9px 17px;
	font-family: 'Mont';
	@include font-size-custom(19px);
	line-height: 19px;
	color: $cornflower-blue;
	font-weight: 700;
	width: 268px;
	border-radius: 14px;
	background-color: $white;
	border: 2px solid $cornflower-blue;
	
	&:active, &:hover {
		color: $white;
		background: $cornflower-blue;
		border: 2px solid $cornflower-blue;
	}
}

.previous-button {
	@extend .next-button;
}

.btn-primary {
	color: $white;
	background: $cornflower-blue;
	border: 2px solid $cornflower-blue;

	&:hover {
		color: $cornflower-blue;
		background: $white;
	}
}
