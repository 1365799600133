.tag-chip{
  float: left;
  display: flex;
  align-items: center;
  min-height: 30px;
  margin: 2px;
  line-height: 22px;
  background-color: rgba(0, 79, 56, 0.2);
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;  
  padding-top: 6px;
  padding-bottom: 6px;
  overflow: hidden;
}

.tag-chip:focus {
  border-color: #40a9ff;
  background-color: #e6f7ff;
}
 
.tag-chip svg {
  cursor: pointer;
  padding: 4px;
}
 
.tag-chip svg {
  font-size: 20px;
}

.end-Chip{
  clear: both;
}