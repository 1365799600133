.back-student-root{
  border: 1px solid #615EFF;
   width: 45px;
   border-radius: 45px;
   height: 45px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-position-y: -1px;
   background-size: contain;
   background: #615EFF;
}

.back-student-root-img{
  width: 37.5px;
  height: 37.5px;
}