@import 'src/scss/mixins.scss';

.container-details-info {
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.container-details-tabs-licence {
	display: flex;
	width: 100%;
	position: relative;
	background: rgba(0, 79, 56, 0.15);
	border-radius: 12px;
	overflow: hidden;
}

.container-details-tab {
	width: 33.35%;
	text-align: center;
	padding: 1vh;
	cursor: pointer;
}

.active {
	background: #004f38;
	font-family: 'Mont';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	color: white;
}
