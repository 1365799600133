@import '../../../scss/mixins.scss';

.root-btn-simple{
  padding: 9px;
  padding-left: 25px;
  padding-right: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.little-btn{
  height: 40px;
  padding: 2px 4px;
}

.little-btn{
  font-size: 16px;
}

.full-width{
  width: 100%;
}

.borderRadius{
  border-radius: 34px;
}

.notBorderRadius{
  border-radius: 8px;
}

.root-btn-simple:hover{
  transform: scale(0.99);
}

.root-btn-simple:active{
  transform: scale(0.98);
}