@import '../../scss/variables.scss';

.background-black {
	background-color: rgba(0, 0, 0, 0.322);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.drawer {
	overflow-y: auto;
	position: fixed;
	right: 0;
	width: 30%;
	height: 100%;
	background-color: white;
	z-index: 110;
	top: 0;
	display: none;
}

.animate-drawer-open {
	display: block;
	animation: drawer-open 500ms;
}

@keyframes drawer-open {
	0% {
		right: -50%;
	}
	100% {
		right: 0;
	}
}

.animate-drawer-close {
	animation: drawer-close 500ms;
}

@keyframes drawer-close {
	0% {
		right: 0%;
	}
	80% {
		right: -50%;
	}
	100% {
		display: none;
	}
}

.middle-width {
	width: 50%;
}

.pop-drawer {
	display: flex;
	align-items: center;
	justify-content: space-between;
  margin-bottom: 30px;

	&__btns {
		display: flex;
		align-items: center;

		button {
			margin-left: 8px;
		}
	}
}

.arrow-back {
  display: flex;
  align-items: center;

  p {
    font-size: 18px;
    line-height: 18px;
    color: $grey-text;
    margin: 0;
  }

	.MuiButtonBase-root {
		padding: 0;
    margin-right: 8px;
	}

	.MuiSvgIcon-fontSizeSmall {
		font-size: 1.8rem;
		color: $grey-text;
	}
}
