@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';

.root-student {
	// margin: 0;
	// padding: 0;
	// position: absolute;
	// width: 100%;
	// height: 100%;
	// overflow-y: scroll;
	display: flex;
	justify-content: flex-end;
}

.menu-student {
	//width: 20%;
	width: 271px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border-right: 2px solid #004f38;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 12;
}

@media (max-width: 991px) {
	.menu-student {
		width: 100%;
		//position: relative;
		height: auto;
		border-right: none;
	}
}

.content-logo {
	width: 100%;
	// height: 14vh;
	display: flex;
	align-items: center;
	margin: 40px 0 25px;
	padding: 0 24px;

	@media (max-width: 991px) {
		margin: 20px 0;
	}
}

.content {
	//position: absolute;
	//width: 80%;
	//left: 20%;
	// padding: 5vh 5vh;
	width: calc(100% - 271px);
	height: 100vh;
	position: relative;

	@media (max-width: 991px) {
		width: 100%;
	}

	&__final {
		background: $fava-green;
	}
}

.logo {
	width: 55px;
	height: 43px;
	cursor: pointer;
}

.content-border {
	margin: 0;
	padding: 22px 24px;
	border-top: 2px solid #004f38;
	border-bottom: 2px solid #004f38;
	cursor: pointer;
}

.text-title-btn {
	margin: 0 2vh;
	font-family: 'Mont Bold';
	font-style: normal;
	font-size: 20px;
	line-height: 20px;
	color: #615eff;
}

.img-content {
	width: 150px;
	height: 150px;
	border-radius: 150px;
}

.content-menu-student {
	padding: 25px 24px;
	overflow-y: auto;

	&__title {
		margin-bottom: 50px;

		.heading-1 {
			font-size: 36px;
			line-height: 45px;
		}
	}

	.heading-1 {
		@include font-size-custom(36px);
		line-height: 45px;
	}

	&__image {
		margin-bottom: 50px;

		.img-content {
			width: 150px;
			height: 150px;
			border-radius: 50%;
		}
	}
}

@media (max-width: 1100px) {
	.content-menu-student {
		display: none;
	}
}

.content-border-bottom-content {
	border-top: 2px solid #004f38;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 9vh;
}

.content-children {
	position: relative;
	height: 100%;
	overflow-y: auto;
	padding: 30px 45px;

	@media (max-width: 991px) {
		padding: 230px 20px 30px;
	}

	.root-space-between {
		margin-bottom: 32px;
	}

	&__desc {
		max-width: 600px;
		width: 100%;
		margin: 30px auto;

		@media (max-width: 767.99px) {
			max-width: 100%;
		}
	}
}

.content-quiz-final {
	&__title {
		@include font-size-custom(40px);
		line-height: 48px;
		letter-spacing: 1.2px;
		color: $white;
	}

	&__desc {
		@include font-size-custom(20px);
		line-height: 39px;
		color: $white;
	}
}

.type-layout h3 {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-size: 19px;
	line-height: 28px;
	color: #004f38;
}

.description-layout-student {
	p {
		font-family: 'Mont';
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 22px;
	}
}

@media (max-width: 1100px) {
	.description-layout-student h2 {
		font-size: 20px;
	}
}

.full-abs {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.full-vh {
	height: 100vh;
}

.hstack {
	@media (max-width: 575.99px) {
		flex-direction: column;
	}
}
