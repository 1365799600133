@import '../../scss/mixins.scss';
@import '../../scss/variables.scss';
@import '../../custom.scss';

.card-vertical {
	border: 2px solid #004f38;
	border-radius: 40px;
	overflow: hidden;


	&__text {
		margin-top: 1rem;
		margin-bottom: 2rem;
		font-family: 'Mont';
		@include font-size-custom(16px);
		line-height: 2;
	}

	&__title {
		overflow: hidden;
		@include font-size-custom(24px);
		font-family: 'MADE Mellow';
		font-weight: 500;
		margin: 1.5rem 0 0;
		color: $fava-green;
	}

	&__body {
		padding: 2.4rem;
	}
}

.card-img {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.vertical-card {
	&-list {
		margin-bottom: -2rem;
	}

	&-item {
		margin-bottom: 2rem;
	}
}


