@import '../../../../../scss/mixins.scss';

.content-validation-border-bottom-content {
	border-top: 2px solid #004f38;
	position: fixed;
	bottom: 0;
	width: calc(100% - 271px);
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.9);
	right: 0;
	//bottom: 0;
	// height: 20vh;
	// padding: 3vh;
	padding: 13rem 2.5rem;
	transition: 500ms;
	bottom: -100%;
	z-index: 12;

	@media (max-width: 991px) {
		width: 100%;
	}

	&.view-modal-failed {
		padding: 6rem 2.5rem 10rem;

		h2 {
			color: #ff7e7e;
		}
	}

	h2 {
		font-family: 'MADE Mellow';
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		line-height: 28px;
		color: #004f38;
		margin: 4px 0 0 8px;
	}

	h3 {
		font-family: 'Mont Bold';
		font-style: normal;
		font-size: 14px;
		line-height: 20px;
		color: #004f38;
	}

	p {
		font-family: 'Mont';
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		color: #004f38;
	}
}

// .content-validation-border-bottom-content.view-modal-failed {
// 	padding: 6rem 2.5rem 10rem;
// }

// @media (max-width: 1100px) {
// 	.content-validation-border-bottom-content {
// 		width: 100%;
// 		left: 0%;
// 	}
// }

// .content-validation-border-bottom-content h2 {
// 	font-family: 'MADE Mellow';
// 	font-style: normal;
// 	font-weight: bold;
// 	font-size: 28px;
// 	line-height: 28px;
// 	color: #004f38;
// 	margin: 4px 0 0 8px;
// }

// .content-validation-border-bottom-content.view-modal-failed h2 {
// 	color: #ff7e7e;
// }

// .content-validation-border-bottom-content h3 {
// 	font-family: 'Mont Bold';
// 	font-style: normal;
// 	font-size: 14px;
// 	line-height: 20px;
// 	color: #004f38;
// }

// .content-validation-border-bottom-content p {
// 	font-family: 'Mont';
// 	font-style: normal;
// 	font-weight: 700;
// 	font-size: 14px;
// 	line-height: 20px;
// 	color: #004f38;
// }

.content-view-modal {
	bottom: 0;
}

.view-modal-failed .root-center-flex,
.view-modal-failed h3 {
	color: #ff7e7e;
}

.icon-validation-answer {
	width: 44px;
	height: 44px;
}

.why-response-question {
	padding: 0 4vh;
}

.response-correct {
	width: 100%;
	max-width: 446px;
}

.incorrect-ans {
	display: flex;
	align-items: center;
}

.content-validation-wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media (max-width: 767.99px) {
		flex-direction: column;
	}
}

.content-view-modal {
	.content-validation-wrap {
		> div {
			width: 33.333%;

			@media (max-width: 767.99px) {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		.cntd-btn {
			text-align: right;

			@media (max-width: 767.99px) {
				text-align: center;
			}
		}
	}
}

.view-modal-failed {
	.content-validation-wrap {
		> div {
			width: 25%;

			@media (max-width: 767.99px) {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		.cntd-btn {
			text-align: right;

			@media (max-width: 767.99px) {
				text-align: center;
			}
		}
	}
}

.answer {
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 18px;
	border-radius: 34px;
	border: 2px solid #615eff;
	display: flex;
	align-items: center;
	color: #615eff;
	background-color: white;
	padding: 6px 8px;
	margin-bottom: 32px;

	span {
		border: 2px solid #615eff;
		width: 39px;
		height: 39px;
		border-radius: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		flex-shrink: 0;
	}

	&--no-text {		
		display: inline-block;
		background: none;
		border: none;
	}
}

.response-failed {
	padding-right: 30px;
}
