@import '~bootstrap/scss/bootstrap-grid.scss';
.container-fluid {
	&--max-design {
		max-width: 1255px;
	}

	padding-left: 2rem;
	padding-right: 2rem;

	@include media-breakpoint-up(lg) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.form-control {
	@include font-size-custom(16px);
	padding-left: 20px;
	padding-right: 20px;
}

.row > * {
	padding-left: 1rem;
	padding-right: 1rem;
}

.row {
	margin-left: -1rem;
	margin-right: -1rem;
}
