@import '../../scss/mixins.scss';
.good-response{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  width: 163px;
  height: 163px;
  border: 1px solid #004F38;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;

  &__title {
    margin-bottom: 20px;
  }
}

.good-response h1{
  font-family: 'MADE Mellow';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 50px;
  color: #615EFF;
  margin: 0;
}

.good-response-color-light{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  width: 200px;
  height: 200px;
  border: 3px solid #fff;
  margin-left: 10px;
  margin-right: 10px;
}

.good-response-color-light h1{
  font-family: 'MADE Mellow';
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 25px;
  color: #fff;
}

.img-icon-good-reponse{
  width: 75px;
  height: 75px;
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.margin-in-score{
  margin-top: 4vh;
  margin-bottom: 2vh;
}