$colors: (
	white: #ffffff,
	black: #000000,
	fava-green: #004f38,
	sea-shell-peach: #fff6ee,
	mine-shaft: #323232,
	cornflower-blue: #615eff,
	turbo: #ffea00,
	rose: #fd99ff,
	vivid-tangerine: #ff7e7e,
	user-rose: #b685f7,
	grey-disabled: #DBDBDB,
	grey-light: #f3f3f3,
	grey-text: #444141
);

$white: map-get($colors, white);
$black: map-get($colors, black);
$fava-green: map-get($colors, fava-green);
$sea-shell-peach: map-get($colors, sea-shell-peach);
$mine-shaft: map-get($colors, mine-shaft);
$cornflower-blue: map-get($colors, cornflower-blue);
$turbo: map-get($colors, turbo);
$rose: map-get($colors, rose);
$vivid-tangerine: map-get($colors, vivid-tangerine);
$user-rose: map-get($colors, user-rose);
$grey-disabled: map-get($colors, grey-disabled);
$grey-light: map-get($colors, grey-light);
$grey-text: map-get($colors, grey-text);
