@import '../../../../../scss/mixins.scss';
@import '../../../../../scss/variables.scss';

.img-logo-quizz {
	position: absolute;
	left: 0;
}

.img-apercu-content-question {
	// width: 330px;
	// height: 332px;
	width: 100%;
	height: auto;
	border-radius: 40px;
}

// .root-content-question-response {
// 	margin: 5vh 0;
// }

// .root-content-question-response h2 {
// 	font-family: 'Montserrat', sans-serif;
// 	font-style: normal;
// 	font-weight: 700;
// 	font-size: 24px;
// 	line-height: 39px;
// 	text-align: center;
// 	color: #004f38;
// 	width: 60%;
// 	margin: 1vh auto;
// }

.root-container-question {
	border: 1px solid;
	display: flex;
	justify-content: start;
	flex-direction: row;
}

.trout-question-content {
	min-width: 150px;
	height: 35px;
	margin-right: 15px;
	margin-left: 15px;
	border-bottom: 3px solid #fd99ff;
	padding-left: 10px;
	padding-right: 10px;
	color: #fd99ff;
}

.content-btn {
	&__wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 600px;
		width: 100%;
	}

	&__question {
		width: 100%;
		border-radius: 40px;

		&.btn-clicked-question {
			background: $cornflower-blue;
		}

		&--bordered {
			border: 0.2rem solid $cornflower-blue;
		}

		&--bg-white {
			background: $white;
		}

		&--four-margin {
			margin: 1rem;
			width: 252px;
		}

		.root-content-question-response--with-image & {
			width: 100%;
		}
	}
}

.response-image {
	position: relative;
	width: 220px;
	height: 220px;
	margin: 0 20px 32px;
	border-radius: 40px;
	border: 2px solid transparent;

	@media (max-width: 767.99px) {
		width: 150px;
		height: 150px;
		margin: 0 30px 32px;
	}

	@media (max-width: 575.99px) {
		width: 120px;
		height: 120px;
		margin: 0 15px 20px;
	}

	/* 	.btn-clicked-question & {
		border-color: $cornflower-blue;
	} */

	img {
		width: 100%;
		height: 100%;
		border-radius: 40px;
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 20px;
		left: 20px;
		width: 39px;
		height: 39px;
		background: $white;
		border: 2px solid $cornflower-blue;
		border-radius: 50%;
		font-family: 'Mont';
		@include font-size-custom(16px);
		line-height: 16px;
		color: $cornflower-blue;

		@media (max-width: 575.99px) {
			top: 10px;
			left: 10px;
			width: 30px;
			height: 30px;
		}

		.btn-clicked-question & {
			color: $white;
			background: $cornflower-blue;
			border: 2px solid $white;
		}
	}
}
